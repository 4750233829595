import React, { useEffect, useState } from 'react';
import toastr from 'toastr';

import {
  Container,
  Col,
  Row,
  Button,
  Form,
  Card,
  ListGroup,
  ListGroupItem,
  Tabs,
  Tab
} from 'react-bootstrap';

import RestApiV2 from '../../api/RestApiV2';
import JobPermittance from './JobPermittance';
import FarmPermittance from './FarmPermittance';
import { RemoveUserAll } from './OrganizationEditHelper';
import { FormattedMessage } from 'react-intl';
import DeleteWarningModal from './DeleteWarningModal';
import { useApi } from '../../hooks/useApi';
import {
  OrgsApi,
} from '@raptormaps/raptor-internal-client-ts';
import { useFeature } from '@growthbook/growthbook-react';


//Models
import { Org } from '@raptormaps/raptor-models';
import User from '../../models/User';

const SAVING = 'SAVING';
const SAVED = 'SAVED';
interface OrganizationEditProps {
  org: Org,
  users: User[],
  getOrgUsers: () => Promise<void>
  replaceUser: (user: User) => void
}

/**@class OrganizationEdit
 * This class serves as the parent class for the job permissions and the solar farm permissions.
 * Contains the tabs the allows the user to navigate from one to the other, displays the number
 * of total users in the org and allows the user to add more users to the org. */
const OrganizationEdit = (props: OrganizationEditProps) => {
  const { org, users, getOrgUsers, replaceUser } = props;

  const [selectedUser, setSelectedUser] = useState<User | undefined>();

  const [permissionButtonMode, setPermissionButtonMode] = useState<string | null>(null);
  const [addUserLoading, setAddUserLoading] = useState(false);
  const [addedUserEmail, setAddedUserEmail] = useState('');

  const [showRemoveAllModal, setShowRemoveAllModal] = useState<boolean>(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);

  const useNewOrgPermissions = useFeature('edit-org-permissions-v2');

  const api = new RestApiV2();

  const OrgsApiClient = useApi(OrgsApi, {
    basePath: window.REACT_APP_API_ENDPOINT_V2,
  });

  /**Adds a user to the org, refreshes the org immediately after*/
  const addUser = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!addedUserEmail) return;

    setAddUserLoading(true);

    try {

      await OrgsApiClient.addOrgUser({
        add_org_user_input: {
          orgId: org.id,
          email: addedUserEmail.toLowerCase()
        } 
      })

      toastr.success(`User (${addedUserEmail}) has been successfully added and notified via email`);

      setAddedUserEmail('');
      await getOrgUsers();
    } catch (err) {
      toastr.error(`Error adding user (${addedUserEmail}) to organization!`)
      console.log(err);
    }

    setAddUserLoading(false);
  };

  /**Removes a user from the org*/
  const removeUser = async () => {
    if (!selectedUser) return;

    try {
      const result = await api.post('/remove_org_user', {
        org_id: org.id,
        user_id: selectedUser.id
      })

      await getOrgUsers();

      if (result.exit_status === 0) {
        toastr.success(`Successfully removed user ${selectedUser.email} from org`);
      }
    } catch (err) {
      console.log(err);
    }
  }


  /**Saves the permissions changed by the user.*/
  const savePermissions = async () => {
    setPermissionButtonMode(SAVING);

    try {
      await api.put(`/orgs/${org.id}/permittances`, {
        org_id: org.id,
        users: users,
      });

      if (useNewOrgPermissions.on) {
        await OrgsApiClient.updateOrgPermissionsOrgsOrgIdFgaPermittancesPut({
          org_id: org.id, body: {
            org_id: org.id,
            users: users,
          }
        });
      }
      setPermissionButtonMode(SAVED);
    } catch (err) {
      toastr.error(JSON.stringify(err));
      console.log(err);
      setPermissionButtonMode('');
    }

  }

  /**Updated the permissions of a single user. */
  const changePermissions = (userId: number, permission: string) => {
    setPermissionButtonMode(null);

    let selectedUser = users.find(user => user.id === userId);
    if (!selectedUser) return;

    // Switch user's permission.
    selectedUser.permittance[permission] = !selectedUser.permittance[permission];

    // If user can download or edit jobs by default, they can also view.
    if (
      (permission === 'can_download_jobs_by_default' ||
        permission === 'can_edit_jobs_by_default') &&
      selectedUser.permittance[permission] === true
    ) {
      selectedUser.permittance['can_view_jobs_by_default'] = true;
    }

    // If they can't view by default, they can't download or edit.
    else if (
      permission === 'can_view_jobs_by_default' &&
      selectedUser.permittance[permission] === false
    ) {
      selectedUser.permittance['can_download_jobs_by_default'] = false;
      selectedUser.permittance['can_edit_jobs_by_default'] = false;
      setShowRemoveAllModal(true);
    }

    //farms permissions
    //if the edit permission is selected then the view is also selected.
    if (permission === 'can_edit_farms_by_default') {
      selectedUser.permittance['can_view_farms_by_default'] = true;
    }

    //if the manage work orders is selected then the view is also selected.
    if (permission === 'can_manage_work_orders') {
      selectedUser.permittance['can_view_farms_by_default'] = true;
    }
    //if view farms is de selected then the user cannot edit or manage work orders by default
    else if (
      permission === 'can_view_farms_by_default' &&
      selectedUser.permittance[permission] === false
    ) {
      selectedUser.permittance['can_edit_farms_by_default'] = false;
      selectedUser.permittance['can_manage_work_orders'] = false;
      setShowRemoveAllModal(true);
    }

    replaceUser(selectedUser);
  };


  const formatData = (cell: any, row: any, permission: string) => {
    return (
      <Form.Check
        type="checkbox"
        checked={row.permittance[permission]}
        onChange={() => {
          changePermissions(row.permittance.user_id, permission);
        }}
        id={permission.concat(row.id)}
        key={permission.concat(row.id)}
        disabled={permissionButtonMode === SAVING}
        label=""
        custom
      />
    );
  };

  /**This function is for showing the modal, not the actual api call.*/
  const onRemoveUser = (user: User) => {
    setShowDeleteUserModal(true);
    setSelectedUser(user);
  };

  const calcAddUserProps = () => {
    let userCardProps = {
      validationState: '',
      inputDisabled: false,
      buttonDisabled: false,
      label: (
        <FormattedMessage
          id="app.orgeditpage.add_user_org"
          defaultMessage="Add user to your organization"
          description="Add user org button"
        />
      ),
      buttonStyle: 'default',
      buttonText: (
        <FormattedMessage
          id="app.orgeditpage.add_user"
          defaultMessage="Add User"
          description="Add user button"
        />
      )
    };

    // user saved successfully
    if (addUserLoading) {
      userCardProps.inputDisabled = true;
      userCardProps.buttonDisabled = true;
      userCardProps.buttonStyle = 'info';
      userCardProps.buttonText = <div>Saving...</div>;
    }

    return userCardProps;
  }


  const PermissionButton = () => {
    let bS = {
      text: (
        <FormattedMessage
          id="app.popups.save_permissions"
          defaultMessage="Save Permissions"
          description="Save permissions button in modal"
        />
      ),
      disabled: false
    };
    if (permissionButtonMode === SAVING) {
      bS.text = <div>Saving...</div>;
      bS.disabled = true;
    } else if (permissionButtonMode === SAVED) {
      bS.text = (
        <FormattedMessage
          id="app.orgeditpage.permissions_saved"
          defaultMessage="Permissions Saved"
          description="Permissions saved button"
        />
      );
      bS.disabled = true;
    }
    return (
      <Button
        className="save-permissions-button"
        disabled={bS.disabled}
        onClick={savePermissions}
        block
      >
        {bS.text}
      </Button>
    )
  };

  const userCardProps = calcAddUserProps();

  return (
    <div className="org-view">
      <Container fluid>
        <Row style={{ paddingTop: '20px' }}>
          <Col xs={12} sm={4} md={4} style={{ paddingLeft: '0' }}>
            <Card>
              <Card.Header>{org.name}</Card.Header>
              <ListGroup>
                <ListGroupItem>
                  <strong>
                    <FormattedMessage
                      id="app.orgeditpage.no_of_users"
                      defaultMessage="Number of Users"
                      description="Number of users display"
                    />
                    &nbsp;
                  </strong>
                  {users.length}
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
          <Col sm={8} md={8} />
        </Row>
        <Row style={{ flexDirection: 'column', paddingTop: '15px' }}>
          <Tabs id="permissions-tabs">
            <Tab
              eventKey={1}
              style={{ color: 'rgb(243, 124, 32)' }}
              title={
                <FormattedMessage
                  id="app.orgeditpage.solar_report_permissions"
                  defaultMessage="Solar Report Permissions"
                  description="Solar report permissions tab"
                />
              }
            >
              <FarmPermittance
                users={users}
                formatData={formatData}
                onRemoveUser={onRemoveUser}
              />
            </Tab>
            <Tab
              eventKey={2}
              title={
                <FormattedMessage
                  id="app.orgeditpage.image_set_permissions"
                  defaultMessage="Image Set Permissions"
                  description="Image Set permissions tab"
                />
              }
            >
              <JobPermittance
                users={users}
                formatData={formatData}
                onRemoveUser={onRemoveUser}
              />
            </Tab>
          </Tabs>
        </Row>
        <Row>
          <Col xs={12} sm={4} md={4} style={{ paddingLeft: '0', marginBottom: '100px' }}>
            <div style={{ padding: '15px 0px 10px' }}>
              <PermissionButton />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={4} md={4} style={{ paddingLeft: '0' }}>
            <Card key='addUserCard' style={{ padding: '15px', marginBottom: '15px' }}>
              <Form onSubmit={addUser}>
                <strong><Form.Label>{userCardProps.label}</Form.Label></strong>
                <Form.Group controlId="addUserEmail">
                  <Form.Control
                    onChange={event => {
                      setAddedUserEmail(event.target.value);
                    }}
                    value={addedUserEmail}
                    type="email"
                    disabled={addUserLoading}
                    placeholder='Enter email address'
                  />
                </Form.Group>
                <Button
                  disabled={!addedUserEmail || addUserLoading}
                  type="submit"
                  style={{ width: '100%' }}
                >
                  {userCardProps.buttonText}
                </Button>
              </Form>
            </Card>
          </Col>
          <Col sm={8} md={8} />
        </Row>
      </Container>

      {/* Modal that shows when the user un-checks the view all box */}
      <RemoveUserAll
        user={selectedUser}
        show={showRemoveAllModal}
        onHide={() => { setShowRemoveAllModal(false) }}
      />

      {/*Modal that warns user when deleting another user from the org.
          Since it depends heavily on the state of this component, I kept it in this file. */}
      <DeleteWarningModal
        show={showDeleteUserModal}
        toggle={() => { setShowDeleteUserModal(false) }}
        header={
          <FormattedMessage
            id="app.orgeditpage.warning"
            defaultMessage="Warning"
            description="Warning modal title"
          />
        }
        body={
          <FormattedMessage
            id="app.orgeditpage.permanently_remove"
            values={{ name: selectedUser ? selectedUser.email : '' }}
            defaultMessage={`Are you sure you would like to permanently remove {name} from your organization`}
          />
        }
        deleteItem={removeUser}
      />
    </div>
  );
}


export default OrganizationEdit;
